import React from 'react';
import diagram from './diagram.png';

function Landing() {
    return (
        <div className="min-h-screen flex flex-col">
            <div className="line-height[1.4] text-base p-0 px-6 my-6 mx-auto max-w-2xl">
                <div id="maincontent" className="max-w-3xl my-4 mt-18 mx-auto">
                    We're building digital humans that are indistinguishable from real ones.<br />
                    <br />Specifically, they:
                    <ul className="list-disc pl-6 mt-0">
                        <li className="ml-2">Hold real-time, multimodal video interactions</li>
                        <li className="ml-2">Intelligently control their body: write, gesticulate, clap, etc.</li>
                        <li className="ml-2">Have intelligent spatial awareness: walk around and interact with their environment</li>
                    </ul>
                    <img src={diagram} alt="Diagram description" className="w-full mt-6" />
                    <br />
                    <div className="text-center mt-4">
                        Backed by a16z speedrun, BoxGroup, Pareto, Sunflower, SV Angel, Z Fellows, <br/>+ amazing angels
                    </div>

                    <br />
                    <br />
                    <br />
                    <div className="text-center mt-4">
                        <a href="mailto:elias@canopylabs.ai" >We're looking for smart, ambitious people to join us</a>
                        <br />
                    </div>
                </div>
            </div>
            <div className="mt-auto pb-6">
                <div className="flex justify-center px-6 mx-auto max-w-2xl">
                    <a href="mailto:contact@canopylabs.ai" className="text-customAccent">Contact Us</a>
                </div>
            </div>
        </div>
    );
}

export default Landing;
